import * as Yup from 'yup';

export const MIN_DEV_FEE = 0.1845;
export type OptimizationType = 'epc' | 'discount' | 'dev';

const escalatorOptions = [
  {label: "0.00%", value: "0.00"},
  {label: "0.49%", value: "0.0049"},
  {label: "0.75%", value: "0.0075"},
  {label: "0.99%", value: "0.0099"},
  {label: "1.00%", value: "0.0100"},
  {label: "1.25%", value: "0.0125"},
  {label: "1.49%", value: "0.0149"},
  {label: "1.50%", value: "0.0150"},
  {label: "1.99%", value: "0.0199"},
  {label: "2.00%", value: "0.0200"},
  {label: "2.25%", value: "0.0225"},
  {label: "2.49%", value: "0.0249"},
  {label: "2.50%", value: "0.0250"},
  {label: "2.75%", value: "0.0275"},
  {label: "2.99%", value: "0.0299"},
  {label: "3.00%", value: "0.0300"}
];

const numberPattern = /^\d*\.?\d*$/;
const signedNumberPattern = /^-?\d*\.?\d*$/;

export const createValidationSchema = (values, systemDesignData, opportunityState) => {
  const schema = {};
  formFields.forEach(field => {
    if (shouldRenderInput(field, values, systemDesignData, opportunityState)) {
      switch (field.name) {
        case 'pv_size_dc_kw_qt':
        case 'pv_size_dc_kw_sd':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(25, "The entered system size is outside of the permitted range of (25kWdc - 15,000kWdc)")
            .max(15000, "The entered system size is outside of the permitted range of (25kWdc - 15,000kWdc)")
            .test('is-decimal', 'Invalid format', value => 
              value === undefined || numberPattern.test(value.toString()));
          break;

        case 'avoided_cost_per_kwh_qt':
        case 'avoided_cost_per_kwh_sd':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(0, "The entered avoided cost is outside of the permitted range ($0.0000/kWh - $0.9999/kWh)")
            .max(0.9999, "The entered avoided cost is outside of the permitted range ($0.0000/kWh - $0.9999/kWh)")
            .test('is-decimal', 'Invalid format', value => 
              value === undefined || numberPattern.test(value.toString()));
          break;

        case 'disc_to_pre_system_utility_bill_pcnt_inp':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(-50.00, "The entered pre-system utility bill discount is outside of the permitted range (-50.00% - 99.99%)")
            .max(99.99, "The entered pre-system utility bill discount is outside of the permitted range (-50.00% - 99.99%)")
            .test('is-signed-decimal', 'Invalid format', value => 
              value === undefined || signedNumberPattern.test(value.toString()));
          break;

        case 'kwh_per_kw_qt':
        case 'kwh_per_kw_sd':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(900, "The entered kW/kWh (productivity) is outside of the permitted range (900.00 - 2,300.00kW/kWh)")
            .max(2300, "The entered kW/kWh (productivity) is outside of the permitted range (900.00 - 2,300.00kW/kWh)")
            .test('is-decimal', 'Invalid format', value => 
              value === undefined || numberPattern.test(value.toString()));
          break;

        case 'epc_total_per_watt_inp_qt':
        case 'epc_total_per_watt_inp_sd':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(0, "The entered total EPC cost is outside of the permitted range ($0.00 - $6.00/watt)")
            .max(6, "The entered total EPC cost is outside of the permitted range ($0.00 - $6.00/watt)")
            .test('is-decimal', 'Invalid format', value => 
              value === undefined || numberPattern.test(value.toString()));
          break;

        case 'sr_finl_dev_fee_per_watt_inp':
          schema[field.name] = Yup.number()
            .required('Required')
            .min(0, "The entered SR financing dev fee is outside of the permitted range ($0.00 - $1.50/watt)")
            .max(1.5, "The entered SR financing dev fee is outside of the permitted range ($0.00 - $1.50/watt)")
            .test('is-decimal', 'Invalid format', value => 
              value === undefined || numberPattern.test(value.toString()));
          break;

        case 'state_incentive_yr1_nom_qt':
        case 'state_incentive_yr1_nom_sd':
          schema[field.name] = Yup.number()
          break;

        case 'custom_opp_maintain_cost_applies':
          schema[field.name] = Yup.string().required('Required');
          break;

        case 'custom_opp_maintain_cost_yr1_per_kw':
        case 'custom_opp_maintain_cost_yr6_per_kw':
          if (values?.custom_opp_maintain_cost_applies === 'Yes') {
            schema[field.name] = Yup.number().required('Required');
          }
          break;

        case 'custom_opp_maintain_cost_escal':
          if (values?.custom_opp_maintain_cost_applies === 'Yes') {
            schema[field.name] = Yup.string().required('Required').oneOf(escalatorOptions.map(option => option.value));
          }
          break;

        case 'site_lease_included':
          schema[field.name] = Yup.string().required('Required');
          break;

        case 'site_lease_annual_nom':
        case 'site_lease_term':
          if (values?.site_lease_included === 'Yes') {
            schema[field.name] = Yup.number().required('Required');
          }
          break;

        case 'site_lease_escal':
          if (values?.site_lease_included === 'Yes') {
            schema[field.name] = Yup.string().required('Required').oneOf(escalatorOptions.map(option => option.value));
          }
          break;

        case 'financing_type':
          schema[field.name] = Yup.string().required('A product must be selected to execute a pricing quote');
          break;

        case 'ppa_term_yrs_num':
          if (values?.financing_type === 'PPA') {
            schema[field.name] = Yup.number().required('Required');
          }
          break;
        case 'ppa_escal_orig_term':
          if (values?.financing_type === 'PPA') {
            schema[field.name] = Yup.string().required('Required');
          }
          break;

        case 'lease_term_yrs_num':
        case 'lease_escal_orig_term':
          if (values?.financing_type === 'Lease') {
            schema[field.name] = Yup.string().required('Required');
          }
          break;

        case 'itc_ineligible_nom_qt':
        case 'itc_ineligible_nom_sd':
          schema[field.name] = Yup.number()
            .test('ineligible-cost-validation', 'ITC Ineligible Costs need to be less than EPC Costs', function(value) {
              const epcCostsPerKW = this.parent.epc_total_per_watt_inp_qt || this.parent.epc_total_per_watt_inp_sd;
              const pvSystemSize = this.parent.pv_size_dc_kw_qt || this.parent.pv_size_dc_kw_sd;
              const epcCosts = pvSystemSize * 1000 * epcCostsPerKW;
              if(field.name === 'itc_ineligible_nom_sd')
                return true;
              return value === undefined || value === null || !!(epcCosts && value < epcCosts);
            });
          break;

        case 'custom_useful_life_term':
          schema[field.name] = Yup.number()
            .test('min-above-term', 'The Custom Useful Life must be 35 years or less and must not be lower than the financial product term years', function(value) {
              const financingType = this.parent.financing_type;
              const ppaTerm = this.parent.ppa_term_yrs_num;
              const leaseTerm = this.parent.lease_term_yrs_num;
              
              if (value === undefined || value === null) return true;
              
              if (value > 35) return false;
              
              if (financingType === 'PPA' && ppaTerm) {
                return value >= parseInt(ppaTerm);
              } else if (financingType === 'Lease' && leaseTerm) {
                return value >= parseInt(leaseTerm);
              }
              
              return true;
            });
          break;

        case 'itc_adder_domestic_qt':
        case 'itc_adder_energy_com_qt':
        case 'itc_adder_low_inc_qt':
        case 'itc_adder_domestic_sd':
        case 'itc_adder_energy_com_sd':
        case 'itc_adder_low_inc_sd':
          schema[field.name] = Yup.string().required('All ITC adders have to be configured otherwise turn off ITC Adders by selecting ITC Adders as No');
          break;

        case 'offtaker_holds_rec_rights':
          schema[field.name] = Yup.string().required('Required');
          break;

        case 'ppa_prod_guar_included':
          if (values?.financing_type === 'PPA') {
            schema[field.name] = Yup.string();
          }
          break;

        case 'rec_type':
        case 'rec_group':
        case 'public_school':
        case 'public_entity':
          schema[field.name] = Yup.string().required('Required');
          break;

        case 'ac_system_size_multiplier':
        case 'ac_system_size_estimated':
          schema[field.name] = Yup.number();
          break;

        case 'ac_system_size_kw':
          schema[field.name] = Yup.number().required('Missing required input');
          break;

        case 'utility_name':
          schema[field.name] = Yup.string().required('Please fill out the Utility Name in opportunity');
          break;

        default:
          schema[field.name] = Yup.mixed().required('Required');
      }
    }
  });

  return Yup.object().shape(schema);
};

export const formFields = [
  {
    name: 'pv_size_dc_kw_qt',
    label: 'PV System Size (kWdc)',
    type: 'number',
    flowRestrictions: ["quote-only"],
    withoutDollarSign: true,
    isRequired: true
  },
  {
    name: 'pv_size_dc_kw_sd',
    label: 'PV System Size (kWdc)',
    type: 'number',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    withoutDollarSign: true,
    isRequired: true
  },
  {
    name: 'avoided_cost_per_kwh_qt',
    label: 'Avoided Cost Rate ($/kWh)',
    type: 'number',
    flowRestrictions: ["quote-only"],
    decimals: 4,
    isRequired: true
  },
  {
    name: 'avoided_cost_per_kwh_sd',
    label: 'Avoided Cost Rate ($/kWh)',
    type: 'number',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    decimals: 4,
    isRequired: true
  },
  {
    name: 'disc_to_pre_system_utility_bill_pcnt_inp',
    label: 'Annual Discount to Utility Bill (%) INPUT',
    type: 'number',
    decimals: 1,
    isPercentage: true,
    isRequired: true
  },
  {
    name: 'kwh_per_kw_qt',
    label: 'Productivity (kWh/kW)',
    type: 'number',
    flowRestrictions: ["quote-only"],
    withoutDollarSign: true,
    decimals: 1,
    isRequired: true
  },
  {
    name: 'kwh_per_kw_sd',
    label: 'Productivity (kWh/kW)',
    type: 'number',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    withoutDollarSign: true,
    decimals: 1,
    isRequired: true
  },
  {
    name: 'epc_total_per_watt_inp_qt',
    label: 'Installer Proposed EPC ($/W)',
    type: 'number',
    flowRestrictions: ["quote-only"],
    decimals: 2,
    isRequired: true
  },
  {
    name: 'epc_total_per_watt_sd',
    label: 'Engineered EPC ($/W)',
    type: 'number',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    decimals: 2,
    isRequired: true
  },
  {
    name: 'itc_ineligible_nom_qt',
    label: 'ITC Ineligible Cost (Quote)',
    flowRestrictions: ["quote-only"],
    type: 'number',
    decimals: 2,
    isRequired: false
  },
  {
    name: 'itc_ineligible_nom_sd',
    label: 'ITC Ineligible Cost (System Design)',
    flowRestrictions: ["requires-system-design"],
    type: 'number',
    disabledOnSD: true,
    decimals: 2,
    isRequired: false
  },
  {
    name: 'sr_finl_dev_fee_per_watt_inp',
    label: 'Sunrock Financing Development Fee ($/W) INPUT',
    defaultValue: 0.369,
    type: 'number',
    decimals: 4,
    helperNote: `Note: Only Finance can save quotes with a dev fee <${MIN_DEV_FEE}`,
    isRequired: false
  },
  {
    name: 'state_incentive_yr1_nom_qt',
    label: 'State Incentive Upfront $',
    type: 'number',
    flowRestrictions: ["quote-only"],
    decimals: 2,
    isRequired: false
  },
  {
    name: 'state_incentive_yr1_nom_sd',
    label: 'State Incentive Upfront $',
    type: 'number',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    decimals: 2, 
    isRequired: false
  },
  {
    name: 'site_lease_included',
    label: 'Site Lease',
    type: 'select',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    isRequired: false
  },
  {
    name: 'site_lease_annual_nom',
    label: 'Annual Site Lease ($/Yr)',
    type: 'number',
    decimals: 2,
    isRequired: true
  },
  {
    name: 'site_lease_term',
    label: 'Site Lease Term',
    type: 'number',
    decimals: 0,
    withoutDollarSign: true,
    isRequired: true
  },
  {
    name: 'site_lease_escal',
    label: 'Site Lease Escalator',
    type: 'select',
    options: escalatorOptions,
    isRequired: true
  },
  {
    name: 'utility_name',
    label: 'Utility Name',
    statesApplicable: ["IL"],
    type: 'text',
    disabled: true,
    isRequired: true
  },
  {
    name: 'rec_type',
    label: 'REC Type',
    type: 'select',
    statesApplicable: ["NJ", "IL", "VA", "MI", "IN", "KY", "NC", "TN"],
    options: [
      {label: 'Adjustable Block Program', value: 'Adjustable Block Program', statesApplicable: ["IL"] },
      {label: 'Successor Solar Incentive (SuSI) Program', value: 'Successor Solar Incentive (SuSI) Program', statesApplicable: ["NJ"]},
      {label: 'VA REC', value: 'VA REC', statesApplicable: ["VA"]},
      {label: 'Pennsylvania Tier I RECs', value: 'Pennsylvania Tier I RECs', statesApplicable: ["MI", "IN", "KY", "NC", "TN", "CA"]}
    ],
    isRequired: true
  },
  {
    name: 'rec_group',
    label: 'REC Group',
    type: 'select',
    statesApplicable: ["IL", "VA", "MI","IN","KY","NC","TN"],
    options: [
      {label: 'Group A: Ameren', value: 'Group A: Ameren', statesApplicable: ["IL"]},
      {label: 'Group B: ComEd', value: 'Group B: ComEd', statesApplicable: ["IL"]},
      {label: 'PJM', value: 'PJM', statesApplicable: ["VA","MI","IN","KY","NC","TN"]},
      {label: 'Not Applicable', value: 'Not Applicable', statesApplicable: ["IL", "VA","MI","IN","KY","NC","TN"]}
    ],
    isRequired: true
  },
  // {
  //   name: 'ac_system_size_kw',
  //   label: 'AC System Size (kW)',
  //   component: 'DisplayOnly',
  //   statesApplicable: ["VA", "IL"],
  //   type: 'number',
  //   flowRestrictions: ["s"],
  //   disabledOnSD: true,
  //   withoutDollarSign: true,
  //   isRequired: true
  // },
  {
    name: 'ac_system_size_multiplier',
    label: 'AC System Size Multiplier',
    component: 'DecimalWrapper',
    statesApplicable: ["VA", "IL"],
    type: 'number',
    defaultValue: 1.25,
    withoutDollarSign: true,
    flowRestrictions: ["quote-only"],
  },
  {
    name: 'ac_system_size_estimated',
    label: 'Estimated AC System Size',
    component: 'DisplayOnly',
    statesApplicable: ["VA", "IL"],
    type: 'number',
    withoutDollarSign: true,
    disable: true,
    flowRestrictions: ["quote-only"],
  },
  {
    name: 'public_school',
    label: 'Public School',
    type: 'select',
    statesApplicable: ["IL"],
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes', statesApplicable: ["IL"]},
      {label: 'No', value: 'No', statesApplicable: ["IL"],}
    ],
    isRequired: true
  },
  {
    name: 'public_entity',
    label: 'Public Entity',
    type: 'select',
    statesApplicable: ["NJ"],
    options: [
      {label: 'Yes', value: 'Yes', statesApplicable: ["NJ"]},
      {label: 'No', value: 'No', statesApplicable: ["NJ"]} 
    ],
    isRequired: true
  },
  {
    name: 'financing_type',
    label: 'Financing Type',
    type: 'select',
    options: [
      {label: 'PPA', value: 'PPA'},
      {label: 'Lease', value: 'Lease'}
    ],
    isRequired: true
  },
  {
    name: 'ppa_term_yrs_num',
    label: 'PPA Term',
    type: 'select',
    options: [
      {label: '15', value: '15'},
      {label: '20', value: '20'},
      {label: '25', value: '25'}
    ],
    root: 'financing_type=PPA',
    defaultValue: '25',
    isRequired: true
  },
  {
    name: 'ppa_escal_orig_term',
    label: 'PPA Escalator',
    type: 'select',
    options: escalatorOptions,
    root: 'financing_type=PPA',
    defaultValue: '0.0199',
    isRequired: true
  },
  {
    name: 'lease_term_yrs_num',
    label: 'Lease Term',
    type: 'select',
    options: [
      {label: '15', value: '15'},
      {label: '20', value: '20'},
      {label: '25', value: '25'}
    ],
    defaultValue: '25',
    root: 'financing_type=Lease',
    isRequired: true
  },
  {
    name: 'lease_escal_orig_term',
    label: 'Lease Escalator',
    type: 'select',
    defaultValue: '0.0199',
    options: escalatorOptions,
    root: 'financing_type=Lease',
    isRequired: true
  },
  {
    name: 'custom_useful_life_term',
    label: 'Custom Useful Life Term',
    type: 'number',
    decimals: 0,
    withoutDollarSign: true,
    isRequired: false
  },
  {
    name: 'itc_adder_domestic_qt',
    label: 'Domestic ITC Adder Eligible',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    flowRestrictions: ["quote-only"],
    isRequired: true
  },
  {
    name: 'itc_adder_domestic_sd',
    label: 'Domestic ITC Adder Eligible',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    isRequired: true
  },
  {
    name: 'itc_adder_energy_com_qt',
    label: 'Energy Community ITC Adder Eligible',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    flowRestrictions: ["quote-only"],
    isRequired: true
  },
  {
    name: 'itc_adder_energy_com_sd',
    label: 'Energy Community ITC Adder Eligible',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    isRequired: true
  },
  {
    name: 'itc_adder_low_inc_qt',
    label: 'Low Income ITC Adder Eligible',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    flowRestrictions: ["quote-only"],
    isRequired: true
  },
  {
    name: 'itc_adder_low_inc_sd',
    label: 'Low Income ITC Adder Eligible',
    type: 'select',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    defaultValue: 'No',
    flowRestrictions: ["requires-system-design"],
    disabledOnSD: true,
    isRequired: true
  },
  {
    name: 'custom_opp_maintain_cost_applies',
    label: 'Custom O&M',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'},
    ],
    isRequired: true
  },
  {
    name: 'custom_opp_maintain_cost_yr1_per_kw',
    label: 'Custom O&M Cost Year 1 ($/kW)',
    type: 'number',
    decimals: 2,
    isRequired: true
  },
  {
    name: 'custom_opp_maintain_cost_yr6_per_kw',
    label: 'Custom O&M Cost Year 6 ($/kW)',
    type: 'number',
    decimals: 2,
    isRequired: true
  },
  {
    name: 'custom_opp_maintain_cost_escal',
    label: 'Custom O&M Cost Escalator',
    type: 'select',
    options: escalatorOptions,
    isRequired: true
  },
  {
    name: 'offtaker_holds_rec_rights',
    label: 'Offtaker Holds REC Rights',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    isRequired: true
  },
  {
    name: 'ppa_prod_guar_included',
    label: 'PPA Production Guaranty Included',
    type: 'select',
    defaultValue: 'No',
    options: [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ],
    root: 'financing_type=PPA',
    isRequired: true
  }
];

// convert "15.000" to 0.15
export const convertPercentToDecimal = (percentageStr: number): number => {
  return Number(percentageStr) / 100;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shouldRenderInput = (field: any, values: any, systemDesignData: any, opportunityState: string) => {
  // Check flow restrictions
  if (field.flowRestrictions && field.flowRestrictions.length > 0) {
    for (const flowRestriction of field.flowRestrictions) {
      switch (flowRestriction) {
        case 'requires-system-design':
          if (!systemDesignData) {
            return false;
          }
          break;
        case 'quote-only':
          if (systemDesignData) {
            return false;
          }
          break;
        default:
          throw new Error(`Unsupported flowRestriction: ${flowRestriction}`);
      }
    }
  }

  // Check existing logic conditions
  if (field.name.includes('custom_opp_maintain_cost') && field.name !== 'custom_opp_maintain_cost_applies') {
    return values?.custom_opp_maintain_cost_applies === 'Yes';
  }

  if (field.name.includes('site_lease') && field.name !== 'site_lease_included') {
    return values?.site_lease_included === 'Yes';
  }

  if (field.name.includes('ppa_') || field.name === 'ppa_prod_guar_included') {
    return values?.financing_type === 'PPA';
  }

  if (field.name.includes('lease_') && field.name !== 'site_lease_included') {
    return values?.financing_type === 'Lease';
  }

  // Check statesApplicable
  if (field.statesApplicable && field.statesApplicable.length > 0) {
    if (!opportunityState || !field.statesApplicable.includes(opportunityState)) {
      return false;
    }
  }

  // Check options statesApplicable
  if (field.type === 'select' && field.options && field.options.length > 0) {
    const applicableOptions = field.options.filter(option => 
      !option.statesApplicable || option.statesApplicable.includes(opportunityState)
    );
    field.options = applicableOptions;
    if (applicableOptions.length === 0) {
      return false;
    }
  }

  // If none of the conditions above return false, the input should render
  return true;
};

export const stringToEscalator = (stringValue: string | undefined) => {
  // escalator needs 4 decimals places 
  const decimalPlaces = 4;
  if (!stringValue) {
    return '';
  }
  const parsedValue = parseFloat(stringValue);
  if (parsedValue === 0) {
    return '';
  }
  return parsedValue.toFixed(decimalPlaces);
}

export const decimalPctgToString = (decimalPctg: number | undefined) => {
  // example: 0.12 => 12%
  // 50 => 50%
  if (!decimalPctg) {
    return 0;
  }
  const parsedValue = isNaN(decimalPctg) ? 0 : decimalPctg;
  if (parsedValue === 0) {
    return 0;
  }

  if (parsedValue >= 1) {
    return parsedValue;
  }
  return (parsedValue * 100);
}