/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { MIN_DEV_FEE } from '../helpers';

interface DecimalTextFieldProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  isPercentage?: boolean;
  withoutDollarSign?: boolean;
  decimals?: number;
  isTouched: boolean;
  isRequired?: boolean;
  helperNote?: string;
}

const DecimalTextField: React.FC<DecimalTextFieldProps> = ({ 
  name, 
  label,
  isPercentage,
  withoutDollarSign,
  isTouched,
  isRequired,
  decimals = 3,
  helperNote,
  ...props }) => {
  const [field, meta, helpers] = useField(name);
  const [acMultiplier, acMeta, acHelpers] = useField('ac_system_size_multiplier');
  const [estimatedAcSize, acEstimatedMeta, acEstimatedHelpers] = useField('ac_system_size_estimated');
  const [pvSystemSize, pvMeta, pvHelpers] = useField('pv_size_dc_kw_qt');
  const [pvSdSystemSize, pvSdMeta, pvSdHelpers] = useField('pv_size_dc_kw_sd');
  const [localValue, setLocalValue] = useState(field.value);
  const [showHelperNote, setShowHelperNote] = useState(false);

  const formatValue = (value: string): string => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      return numValue.toFixed(decimals);
    }
    return value;
  };

  // TEMP hack, refactor when we have users roles
  if (field.name === 'sr_finl_dev_fee_per_watt_inp' && field.value < MIN_DEV_FEE && !showHelperNote)
    setShowHelperNote(true);
  if (field.name === 'sr_finl_dev_fee_per_watt_inp' && field.value >= MIN_DEV_FEE && showHelperNote)
    setShowHelperNote(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    // Allow only digits and a single decimal point
    value = value.replace(/[^\d.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    // Limit to decimals digits after decimal point
    if (parts.length === 2 && parts[1].length > decimals) {
      if (decimals === 0) {
        value = parts[0];
      }
      value = parts[0] + '.' + parts[1].slice(0, decimals);
    }
    if (field.name === 'pv_size_dc_kw_qt' || field.name === 'pv_size_dc_kw_sd') {
      const multiplier = parseFloat(acMultiplier.value);
      const estimatedAcSize = parseFloat(value) / multiplier;
      acEstimatedHelpers.setValue(estimatedAcSize.toString());
    }

    if (field.name === 'ac_system_size_multiplier') {
      const multiplier = parseFloat(value);
      const estimatedAcSize = parseFloat(pvSdSystemSize.value || pvSystemSize.value ) / multiplier;
      acEstimatedHelpers.setValue(estimatedAcSize.toString());
    }
    setLocalValue(value);
    helpers.setValue(value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const formattedValue = formatValue(localValue);
    setLocalValue(formattedValue);
    helpers.setValue(formattedValue);
    field.onBlur(event);
  };

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      value={localValue || field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={(meta.touched || props.disabled) && Boolean(meta.error)}
      helperText={(meta.touched || props.disabled) ? (meta.error || (showHelperNote ? helperNote : '')) : showHelperNote ? helperNote : ''}
      FormHelperTextProps={{sx: {color: showHelperNote ? '#FF7E0B' : 'black'}}}
      inputProps={decimals > 0 ? {
        inputMode: 'decimal',
        pattern: '^-?\\d*\\.?\\d*$'
      } : {}}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">{isPercentage ? '%' : (withoutDollarSign  ? '' : '$')}</InputAdornment>
        )
      }}
      fullWidth
      margin="normal"
      InputLabelProps={{ sx: { fontWeight: 'bold', color: (!isRequired || isTouched) ? 'black' : '#FF7E0B' } }}
      type="number"
    />
  );
};

export default DecimalTextField;